import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@tanstack/react-query";
import styled from "styled-components"
import AdminNetwork from "../modules/network/admin";

const Container = styled.div`
    table {

td {
    padding: .5em;
}

tbody {

    tr:nth-child(2n) {
        background: #9d9d9d;
    }

    tr:nth-child(2n + 1) {
        background: #d9d9d9;
    }
}
}
`;

export default function ScenariosList() {

    const { isLoading, data: scenarios } = useQuery({
        queryKey: ['scenarios-all'],
        queryFn: AdminNetwork.getFullScenarioList
    });
    const [showNewForm, setShowNewForm] = useState(false);

    return <Container>
        <h2>
            Lista scenariuszy
        </h2>
        <div>
            <button onClick={() => setShowNewForm(true)}>Stwórz nowy scenariusz</button>
        </div>
        {showNewForm && <div>
            <NewScenarioForm />
            <button onClick={() => setShowNewForm(false)}>anuluj</button>
        </div>}
        {isLoading && <p>Ładuję...</p>}
        {!isLoading && <div>
            {
                scenarios.map(((s: any) => {
                    return <ScenarioRow scenario={s} />
                }))
            }
        </div>}
    </Container>
}


function ScenarioRow({ scenario }: any) {

    const [showEdit, setShowEdit] = useState(false);

    return <div>
        <div>{scenario.name}</div>
        <div>
            {!showEdit && <button onClick={_ => setShowEdit(true)}>Edit</button>}
            {showEdit && <NewScenarioForm scenario={scenario} />}
            {showEdit && <button onClick={_ => setShowEdit(false)}>Anuluj</button>}
        </div>
    </div>
}

function NewScenarioForm({ scenario }: any) {

    const { register, handleSubmit } = useForm({
        defaultValues: scenario
    });
    const { isLoading, data: chapters } = useQuery({
        queryKey: ['chapters-all'],
        queryFn: AdminNetwork.getChaptersList
    });
    const { mutate } = useMutation({ mutationFn: AdminNetwork.newScenario });

    const onSubmit = useCallback((data) => {
        mutate({
            name: data.name,
            forGM: data.forGM,
            chapter1: data.chapter1 || null,
            chapter2: data.chapter2 || null,
            chapter3: data.chapter3 || null,
            chapter4: data.chapter4 || null,
            chapter5: data.chapter5 || null,
            _id: data._id || null
        });
    }, [mutate]);

    return <>
        <form onSubmit={handleSubmit(onSubmit)}>
            <input type="hidden" {...register('_id')} />
            <table>
                <tbody>
                    <tr>
                        <td>Nazwa</td>
                        <td><input type="text" {...register('name', { required: true })} /></td>
                    </tr>
                    <tr>
                        <td>Czy jest dostępny dla nauczycieli?</td>
                        <td><input type="checkbox" {...register('forGM')} /></td>
                    </tr>
                    <tr>
                        <td>Rozdział 1</td>
                        <td>
                            {!isLoading && <select {...register('chapter1')}>
                                <option value="">-- brak --</option>
                                {
                                    chapters.map((c: any) => {
                                        return <option key={c._id} value={c._id}>{c.name}</option>
                                    })
                                }
                            </select>}
                        </td>
                    </tr>
                    <tr>
                        <td>Rozdział 2</td>
                        <td>
                            {!isLoading && <select {...register('chapter2')}>
                                <option value="">-- brak --</option>
                                {
                                    chapters.map((c: any) => {
                                        return <option key={c._id} value={c._id}>{c.name}</option>
                                    })
                                }
                            </select>}
                        </td>
                    </tr>
                    <tr>
                        <td>Rozdział 3</td>
                        <td>
                            {!isLoading && <select {...register('chapter3')}>
                                <option value="">-- brak --</option>
                                {
                                    chapters.map((c: any) => {
                                        return <option key={c._id} value={c._id}>{c.name}</option>
                                    })
                                }
                            </select>}
                        </td>
                    </tr>
                    <tr>
                        <td>Rozdział 4</td>
                        <td>
                            {!isLoading && <select {...register('chapter4')}>
                                <option value="">-- brak --</option>
                                {
                                    chapters.map((c: any) => {
                                        return <option key={c._id} value={c._id}>{c.name}</option>
                                    })
                                }
                            </select>}
                        </td>
                    </tr>
                    <tr>
                        <td>Rozdział 5</td>
                        <td>
                            {!isLoading && <select {...register('chapter5')}>
                                <option value="">-- brak --</option>
                                {
                                    chapters.map((c: any) => {
                                        return <option key={c._id} value={c._id}>{c.name}</option>
                                    })
                                }
                            </select>}
                        </td>
                    </tr>
                </tbody>
            </table>
            <button>Zapisz</button>
        </form>
    </>
}
