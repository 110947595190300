import { useQuery } from "@tanstack/react-query";
import AdminNetwork from "../../network/admin";

export default function useGMRaport() {
    const {data, isLoading} = useQuery(['gm-raport'], AdminNetwork.getGMRaport, {
        staleTime: Infinity
    });

    return {
        isLoading,
        gameMasters: data
    }
}
