import { useMutation, useQueryClient } from "@tanstack/react-query";
import AdminNetwork from "../network/admin";
import { GMUpdateParams } from "../network/types";

export default function useGameMasterUpdate() {
    const client = useQueryClient();

    const { mutate, isLoading, error, reset } = useMutation(['gm-update'], (data: {gmId: string, params: Partial<GMUpdateParams>}) => {
        return AdminNetwork.updateGM(data.gmId, data.params);
    }, {
        async onSuccess() {
            await client.invalidateQueries(['gm-raport']);
        }
    });

    return {
        isLoading,
        error,
        reset,
        update: mutate
    }
}
