import { useCallback, useMemo } from "react";
import styled from "styled-components";
import useGMRaport from "../modules/admin/hooks/use-gm-raport";
import useGameMasterUpdate from "../modules/game-master/use-game-master-update";

const Container = styled.div`

`;

export default function GMHidden() {

    const { gameMasters, isLoading } = useGMRaport();

    const hiddenGMs = useMemo(() => {
        return (gameMasters || []).filter((gm: any) => gm.hidden)
    }, [gameMasters]);

    const {update} = useGameMasterUpdate();

    const onHideGMClicked = useCallback((gm: any) => {
        update({
            gmId: gm._id,
            params: {
                hidden: false
            }
        });
    }, [update]);

    return <Container>
        {isLoading && <p>Ładuję...</p>}
        {!isLoading && <>
            <table>
                <tbody>
                    {
                        hiddenGMs.map((gm: any) => {

                            return <tr key={gm._id}>
                                <td>{gm.login}</td>
                                <td>{gm.name}</td>
                                <td>{gm.surname}</td>
                                <td>{gm.info}</td>
                                <td><button onClick={() => onHideGMClicked(gm)}>Aktywuj konto</button></td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </>}
    </Container>
}
