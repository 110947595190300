import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { getAdminPanelRoute, getConvertRoute, getGameMastersRoute, getGameRaportRoute, getHiddenGMRoute, getLoginRoute, getOpenGameRoute, getScenariosPanelRoute } from './routes/routes';
import { Suspense } from 'react';
import PanelPage from './routes/panel';
import ScenariosPage from './routes/scenarios';
import ConvertPage from './routes/converts';
import GameMastersPage from './routes/game-masters';
import AdminGameRaportPage from './routes/admin/game-raport';
import LoginPage from './routes/login';
import useAdmin from './modules/admin/hooks/use-admin';
import HiddenGMPage from './routes/hidden-gm';
import OpenGamePage from './routes/open-game';


export default function App() {
    return (
        <BrowserRouter>
            <Suspense fallback={<p>Ładuję...</p>}>
                <Routes>
                    <Route path={getLoginRoute()} element={<LoginPage />} />

                    <Route path={getAdminPanelRoute()} element={<ProtectedRoute><PanelPage /></ProtectedRoute>} />
                    <Route path={getScenariosPanelRoute()} element={<ProtectedRoute><ScenariosPage /></ProtectedRoute>} />
                    <Route path={getConvertRoute()} element={<ProtectedRoute><ConvertPage /></ProtectedRoute>} />
                    <Route path={getGameMastersRoute()} element={<ProtectedRoute><GameMastersPage /></ProtectedRoute>} />
                    <Route path={getGameRaportRoute()} element={<ProtectedRoute><AdminGameRaportPage /></ProtectedRoute>} />
                    <Route path={getHiddenGMRoute()} element={<ProtectedRoute><HiddenGMPage /></ProtectedRoute>} />
                    <Route path={getOpenGameRoute()} element={<ProtectedRoute><OpenGamePage /></ProtectedRoute>} />
                    <Route path={'*'} element={<Navigate to={getAdminPanelRoute()} />} />
                </Routes>
            </Suspense>
        </BrowserRouter>
    )
}


export const ProtectedRoute = ({ children }: React.PropsWithChildren<{}>) => {
    const { isError, isLoading } = useAdmin();
    if (isLoading) {
        console.log('LADUJE!')
        return <p>Ładuję...</p>
    }

    if (isError) {
        console.log('NO LOGGED!')
      // user is not authenticated
      return <Navigate to={getLoginRoute()} />;
    }
    return <>{children}</>;
  };
