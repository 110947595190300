import pattern2Src from '../../assets/patterns/pattern-2.png';
import pattern3Src from '../../assets/patterns/pattern-3.png';
import pattern4Src from '../../assets/patterns/pattern-4.png';
import styled from "styled-components";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import Sticky from 'react-sticky-el';
import { GameInfo, PlayerInfo } from '../../modules/game/use-game-stats';

/**
 * Komponent prezentujący statystyki gry dla pojedynczego gracza
 */

type Props = {
    game: GameInfo
}

const Container = styled.div`
    .controlls {
        background: var(--color1);
        padding: .5em 0;
    }
`;

export default function PlayersStats({ game }: Props) {
    const { register, watch } = useForm<Pick<PlayerProps, 'endingType'>>({
        defaultValues: {
            endingType: 'all'
        }
    })

    const { endingType } = watch();

    return <Container>
        <h3>Gracze</h3>

        <Sticky>
            <div className="controlls">
                <form>
                    <fieldset>
                        <legend>Rodzaje zakończeń</legend>
                        <label><input type="radio" value="all" {...register('endingType')} /> Wszystkie</label><br />
                        <label><input type="radio" value="success" {...register('endingType')} /> Tylko pozytywne</label><br />
                        <label><input type="radio" value="early" {...register('endingType')} /> Tylko negatywne</label>
                    </fieldset>
                </form>
            </div>
        </Sticky>

        {
            game.players.map((player: any) => {
                return <PlayerWidget player={player} key={player.token} endingType={endingType} />
            })
        }
    </Container>
}


type PlayerProps = {
    player: PlayerInfo,
    endingType: 'all' | 'success' | 'early'
}

const PlayerWidgetContainer = styled.div`
    display: grid;

    padding: .5em;
    margin: 1em 0;
    background: var(--color3);

    grid-template-columns: minmax(0, 33%) auto auto repeat(6, 1fr);
    grid-template-rows: auto auto repeat(5, 1fr);

    .earlyEnding {
        color: var(--colorError1);
    }

    &>* {
        min-width: 2em;
        padding: 2px .2em;
    }

    .score-cell {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .score-label {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .count-cell {
        display: flex;
        justify-content: center;
        align-items: center;

        background: white;
    }

    .run-label {
        display: flex;
        justify-content: center;
        align-items: center;

        text-align: center;
    }

    .info {
        display: flex;
        justify-content: center;
        align-items: center;

        grid-column: 1;
        grid-row: 1/8;

        p {
            text-align: center;
        }
    }

    .eco-cell {
        background: url(${pattern3Src});
        background-size: 20%;
        background-attachment: fixed;
    }
    .edu-cell {
        background: url(${pattern2Src});
        background-size: 20%;
        background-attachment: fixed;
    }
    .gra-cell {
        background: url(${pattern4Src});
        background-size: 20%;
        background-attachment: fixed;
    }

    .count-label {
        display: flex;
        justify-content: center;
        align-items: center;

        grid-column: 3;
        grid-row: 1;
    }

    .first-label {
        grid-column: 4/7;
        grid-row: 1;
    }

    .first-eco-label {
        grid-column: 4;
        grid-row: 2;
    }
    .first-edu-label {
        grid-column: 5;
        grid-row: 2;
    }
    .first-gra-label {
        grid-column: 6;
        grid-row: 2;
    }

    .last-label {
        grid-column: 7/10;
        grid-row: 1;
    }

    .last-eco-label {
        grid-column: 7;
        grid-row: 2;
    }
    .last-edu-label {
        grid-column: 8;
        grid-row: 2;
    }
    .last-gra-label {
        grid-column: 9;
        grid-row: 2;
    }

    .chapter-1-label {
        grid-column: 2;
        grid-row: 3;
    }
    .chapter-2-label {
        grid-column: 2;
        grid-row: 4;
    }
    .chapter-3-label {
        grid-column: 2;
        grid-row: 5;
    }
    .chapter-4-label {
        grid-column: 2;
        grid-row: 6;
    }
    .chapter-5-label {
        grid-column: 2;
        grid-row: 7;
    }

    .c1-count {
        grid-column: 3;
        grid-row: 3;
    }
    .c2-count {
        grid-column: 3;
        grid-row: 4;
    }
    .c3-count {
        grid-column: 3;
        grid-row: 5;
    }
    .c4-count {
        grid-column: 3;
        grid-row: 6;
    }
    .c5-count {
        grid-column: 3;
        grid-row: 7;
    }

    .c1-first-eco {
        grid-column: 4;
        grid-row: 3;
    }
    .c1-first-edu {
        grid-column: 5;
        grid-row: 3;
    }
    .c1-first-gra {
        grid-column: 6;
        grid-row: 3;
    }
    .c1-last-edu {
        grid-column: 8;
        grid-row: 3;
    }
    .c1-last-eco {
        grid-column: 7;
        grid-row: 3;
    }
    .c1-last-gra {
        grid-column: 9;
        grid-row: 3;
    }

    .c2-first-edu {
        grid-column: 5;
        grid-row: 4;
    }
    .c2-first-eco {
        grid-column: 4;
        grid-row: 4;
    }
    .c2-first-gra {
        grid-column: 6;
        grid-row: 4;
    }
    .c2-last-edu {
        grid-column: 8;
        grid-row: 4;
    }
    .c2-last-eco {
        grid-column: 7;
        grid-row: 4;
    }
    .c2-last-gra {
        grid-column: 9;
        grid-row: 4;
    }

    .c3-first-edu {
        grid-column: 5;
        grid-row: 5;
    }
    .c3-first-eco {
        grid-column: 4;
        grid-row: 5;
    }
    .c3-first-gra {
        grid-column: 6;
        grid-row: 5;
    }
    .c3-last-edu {
        grid-column: 8;
        grid-row: 5;
    }
    .c3-last-eco {
        grid-column: 7;
        grid-row: 5;
    }
    .c3-last-gra {
        grid-column: 9;
        grid-row: 5;
    }

    .c4-first-edu {
        grid-column: 5;
        grid-row: 6;
    }
    .c4-first-eco {
        grid-column: 4;
        grid-row: 6;
    }
    .c4-first-gra {
        grid-column: 6;
        grid-row: 6;
    }
    .c4-last-edu {
        grid-column: 8;
        grid-row: 6;
    }
    .c4-last-eco {
        grid-column: 7;
        grid-row: 6;
    }
    .c4-last-gra {
        grid-column: 9;
        grid-row: 6;
    }

    .c5-first-edu {
        grid-column: 5;
        grid-row: 7;
    }
    .c5-first-eco {
        grid-column: 4;
        grid-row: 7;
    }
    .c5-first-gra {
        grid-column: 6;
        grid-row: 7;
    }
    .c5-last-edu {
        grid-column: 8;
        grid-row: 7;
    }
    .c5-last-eco {
        grid-column: 7;
        grid-row: 7;
    }
    .c5-last-gra {
        grid-column: 9;
        grid-row: 7;
    }
`;

function PlayerWidget({ player, endingType }: PlayerProps) {

    const c1FirstClass = classNames({ earlyEnding: player.chapter1[endingType].first.earlyEnding });
    const c1LastClass = classNames({ earlyEnding: player.chapter1[endingType].last.earlyEnding });
    const c2FirstClass = classNames({ earlyEnding: player.chapter2[endingType].first.earlyEnding });
    const c2LastClass = classNames({ earlyEnding: player.chapter2[endingType].last.earlyEnding });
    const c3FirstClass = classNames({ earlyEnding: player.chapter3[endingType].first.earlyEnding });
    const c3LastClass = classNames({ earlyEnding: player.chapter3[endingType].last.earlyEnding });
    const c4FirstClass = classNames({ earlyEnding: player.chapter4[endingType].first.earlyEnding });
    const c4LastClass = classNames({ earlyEnding: player.chapter4[endingType].last.earlyEnding });
    const c5FirstClass = classNames({ earlyEnding: player.chapter5[endingType].first.earlyEnding });
    const c5LastClass = classNames({ earlyEnding: player.chapter5[endingType].last.earlyEnding });

    return <PlayerWidgetContainer>
        <div className="info">
            <p>
                {player.token}<br />
                {player.used && <span style={{ color: 'green', fontSize: '.75em' }}>Użyty</span>}
                {!player.used && <span style={{ color: 'red', fontSize: '.75em' }}>Nieużyty</span>}
            </p>
        </div>
        <div className="count-label">
            #
        </div>
        <div className="run-label first-label">
            Pierwsze przejście
        </div>
        <div className="score-label eco-cell first-eco-label">eco</div>
        <div className="score-label edu-cell first-edu-label">edu</div>
        <div className="score-label gra-cell first-gra-label">gra</div>

        <div className="run-label last-label">
            Ostatnie przejscie
        </div>
        <div className="score-label eco-cell last-eco-label">eco</div>
        <div className="score-label edu-cell last-edu-label">edu</div>
        <div className="score-label gra-cell last-gra-label">gra</div>

        <div className="chapter-1-label">Rozdział 1</div>
        <div className="chapter-2-label">Rozdział 2</div>
        <div className="chapter-3-label">Rozdział 3</div>
        <div className="chapter-4-label">Rozdział 4</div>
        <div className="chapter-5-label">Rozdział 5</div>

        {endingType === 'all' && <>
            <div className="count-cell c1-count">{player.chapter1.count} {Boolean(player.chapter1.earlyEndingCount) && <span className="earlyEnding">({player.chapter1.earlyEndingCount})</span>}</div>
            <div className="count-cell c2-count">{player.chapter2.count} {Boolean(player.chapter2.earlyEndingCount) && <span className="earlyEnding">({player.chapter2.earlyEndingCount})</span>}</div>
            <div className="count-cell c3-count">{player.chapter3.count} {Boolean(player.chapter3.earlyEndingCount) && <span className="earlyEnding">({player.chapter3.earlyEndingCount})</span>}</div>
            <div className="count-cell c4-count">{player.chapter4.count} {Boolean(player.chapter4.earlyEndingCount) && <span className="earlyEnding">({player.chapter4.earlyEndingCount})</span>}</div>
            <div className="count-cell c5-count">{player.chapter5.count} {Boolean(player.chapter5.earlyEndingCount) && <span className="earlyEnding">({player.chapter5.earlyEndingCount})</span>}</div>
        </>}

        {endingType === 'success' && <>
            <div className="count-cell c1-count">{player.chapter1.count}</div>
            <div className="count-cell c2-count">{player.chapter2.count}</div>
            <div className="count-cell c3-count">{player.chapter3.count}</div>
            <div className="count-cell c4-count">{player.chapter4.count}</div>
            <div className="count-cell c5-count">{player.chapter5.count}</div>
        </>}

        {endingType === 'early' && <>
            <div className="count-cell c1-count"><span className="earlyEnding">{player.chapter1.earlyEndingCount}</span></div>
            <div className="count-cell c2-count"><span className="earlyEnding">{player.chapter2.earlyEndingCount}</span></div>
            <div className="count-cell c3-count"><span className="earlyEnding">{player.chapter3.earlyEndingCount}</span></div>
            <div className="count-cell c4-count"><span className="earlyEnding">{player.chapter4.earlyEndingCount}</span></div>
            <div className="count-cell c5-count"><span className="earlyEnding">{player.chapter5.earlyEndingCount}</span></div>
        </>}

        <div className={`score-cell eco-cell c1-first-eco ${c1FirstClass}`}>{scoreString(player.chapter1[endingType].first.eco)}</div>
        <div className={`score-cell edu-cell c1-first-edu ${c1FirstClass}`}>{scoreString(player.chapter1[endingType].first.edu)}</div>
        <div className={`score-cell gra-cell c1-first-gra ${c1FirstClass}`}>{scoreString(player.chapter1[endingType].first.gra)}</div>
        <div className={`score-cell eco-cell c1-last-eco ${c1LastClass}`}> {scoreString(player.chapter1[endingType].last.eco)}</div>
        <div className={`score-cell edu-cell c1-last-edu ${c1LastClass}`}> {scoreString(player.chapter1[endingType].last.edu)}</div>
        <div className={`score-cell gra-cell c1-last-gra ${c1LastClass}`}> {scoreString(player.chapter1[endingType].last.gra)}</div>

        <div className={`score-cell eco-cell c2-first-eco ${c2FirstClass}`}>{scoreString(player.chapter2[endingType].first.eco)}</div>
        <div className={`score-cell edu-cell c2-first-edu ${c2FirstClass}`}>{scoreString(player.chapter2[endingType].first.edu)}</div>
        <div className={`score-cell gra-cell c2-first-gra ${c2FirstClass}`}>{scoreString(player.chapter2[endingType].first.gra)}</div>
        <div className={`score-cell eco-cell c2-last-eco ${c2LastClass}`}> {scoreString(player.chapter2[endingType].last.eco)}</div>
        <div className={`score-cell edu-cell c2-last-edu ${c2LastClass}`}> {scoreString(player.chapter2[endingType].last.edu)}</div>
        <div className={`score-cell gra-cell c2-last-gra ${c2LastClass}`}> {scoreString(player.chapter2[endingType].last.gra)}</div>

        <div className={`score-cell eco-cell c3-first-eco ${c3FirstClass}`}>{scoreString(player.chapter3[endingType].first.eco)}</div>
        <div className={`score-cell edu-cell c3-first-edu ${c3FirstClass}`}>{scoreString(player.chapter3[endingType].first.edu)}</div>
        <div className={`score-cell gra-cell c3-first-gra ${c3FirstClass}`}>{scoreString(player.chapter3[endingType].first.gra)}</div>
        <div className={`score-cell eco-cell c3-last-eco ${c3LastClass}`}> {scoreString(player.chapter3[endingType].last.eco)}</div>
        <div className={`score-cell edu-cell c3-last-edu ${c3LastClass}`}> {scoreString(player.chapter3[endingType].last.edu)}</div>
        <div className={`score-cell gra-cell c3-last-gra ${c3LastClass}`}> {scoreString(player.chapter3[endingType].last.gra)}</div>

        <div className={`score-cell eco-cell c4-first-eco ${c4FirstClass}`}>{scoreString(player.chapter4[endingType].first.eco)}</div>
        <div className={`score-cell edu-cell c4-first-edu ${c4FirstClass}`}>{scoreString(player.chapter4[endingType].first.edu)}</div>
        <div className={`score-cell gra-cell c4-first-gra ${c4FirstClass}`}>{scoreString(player.chapter4[endingType].first.gra)}</div>
        <div className={`score-cell eco-cell c4-last-eco ${c4LastClass}`}> {scoreString(player.chapter4[endingType].last.eco)}</div>
        <div className={`score-cell edu-cell c4-last-edu ${c4LastClass}`}> {scoreString(player.chapter4[endingType].last.edu)}</div>
        <div className={`score-cell gra-cell c4-last-gra ${c4LastClass}`}> {scoreString(player.chapter4[endingType].last.gra)}</div>

        <div className={`score-cell eco-cell c5-first-eco ${c5FirstClass}`}>{scoreString(player.chapter5[endingType].first.eco)}</div>
        <div className={`score-cell edu-cell c5-first-edu ${c5FirstClass}`}>{scoreString(player.chapter5[endingType].first.edu)}</div>
        <div className={`score-cell gra-cell c5-first-gra ${c5FirstClass}`}>{scoreString(player.chapter5[endingType].first.gra)}</div>
        <div className={`score-cell eco-cell c5-last-eco ${c5LastClass}`}> {scoreString(player.chapter5[endingType].last.eco)}</div>
        <div className={`score-cell edu-cell c5-last-edu ${c5LastClass}`}> {scoreString(player.chapter5[endingType].last.edu)}</div>
        <div className={`score-cell gra-cell c5-last-gra ${c5LastClass}`}> {scoreString(player.chapter5[endingType].last.gra)}</div>
    </PlayerWidgetContainer>
}


function scoreString(value: number | null) {
    if (value === null) return '-';

    return value.toFixed(1);
}
