import { useCallback } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import useGameMasterUpdate from "../modules/game-master/use-game-master-update";
import { getGameRaportRoute } from "../routes/routes";

type Props = {
    gm: any
}

const Container = styled.div`

    margin: .5em 0;
    padding: .5em;
    background: #d9d9d9;

    display: grid;
    grid-template-columns: 1fr 1fr;
    font-weight: 300;
    font-size: 16px;

    gap: 10px;

    strong {
        font-weight: 500;
    }

    .gm-id {
        grid-row: 1/3;
        grid-column: 2;

        font-size: .75em;
        text-align: right;
    }

    .gm-hide {
        grid-row: 2;
        grid-column: 2;

        text-align: right;
    }

    .gm-name {
        grid-row: 1;
        grid-column: 1;
    }

    .gm-info {
        grid-row: 2;
        grid-column: 1;
    }

    .gm-games {
        grid-row: 4;
        grid-column: 1/3;
    }
    .gm-game {
        background: white;
        padding: .5em;
        display: grid;
        gap: 5px;

        grid-template-columns: 1fr 1fr 1fr;

        .gm-game-code {
            grid-row: 1;
            grid-column: 1;
        }

        .gm-game-players {
            grid-row: 1;
            grid-column: 2;
        }

        .gm-game-desc {
            grid-row: 2;
            grid-column: 1/3;
        }

        .gm-game-to-raport {
            grid-row: 1/3;
            grid-column: 3;

            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
`;

export default function GameMasterRaport({ gm }: Props) {
    const {update} = useGameMasterUpdate();

    const onHideGMClicked = useCallback(() => {
        update({
            gmId: gm._id,
            params: {
                hidden: true
            }
        });
    }, [update, gm]);

    return <Container>
        <div className="gm-id">{gm._id}</div>
        <div className="gm-hide"><button onClick={onHideGMClicked}>Ukryj to konto</button></div>
        <div className="gm-name">Nauczyciel: <strong>{gm.name} {gm.surname} ({gm.login})</strong></div>
        <div className="gm-info">Szkoła: <strong>{gm.info}</strong></div>
        <div className="gm-games-count">Ilośc założonych gier: <strong>{gm.games.length || 0}</strong></div>
        <div className="gm-games">
            {
                gm.games.map((game: any) => {
                    return <div className="gm-game" key={game.code}>
                        <div className="gm-game-code">
                            <strong>{game.code}</strong>
                        </div>
                        <div className="gm-game-players">
                            Graczy: <strong>{game.playersLogged}/{game.playerCount}</strong>
                        </div>
                        <div className="gm-game-desc">
                            {game.description}
                        </div>
                        <div className="gm-game-to-raport">
                            <Link to={getGameRaportRoute(game.code)}>Przejdź do raportu</Link>
                        </div>
                    </div>
                })
            }
        </div>
    </Container>
}
