import { useCallback } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import useAdminLogin from "../modules/admin/hooks/use-admin-login";

const Container = styled.div`
    .form {
        margin: 1em;
    }

    .error {
        margin: 1em 0;
        color: red;
    }
`;


export default function LoginPage() {
    const { register, handleSubmit } = useForm();
    const { login, error } = useAdminLogin();

    const onSubmit = useCallback((data) => {
        login(data)
    }, [login]);

    return <Container>
        <h1>Ekohakaton - panel admina</h1>
        <div className="form">
            <form onSubmit={handleSubmit(onSubmit)}>
                <label>
                    <p>Login</p>
                    <input type="text" {...register('login', { required: true })} />
                </label>
                <label>
                    <p>Password</p>
                    <input type="password" {...register('password', { required: true })} />
                </label>
                <div className="error">
                    {error}
                </div>
                <div>
                    <button>Zaloguj</button>
                </div>
            </form>
        </div>
    </Container>
}
