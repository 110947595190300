import { useMemo } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import AdminNetwork from "../../network/admin";
import { getHomeRoute } from "../../../routes/routes";
import { useNavigate } from "react-router";

export default function useAdminLogin() {
    const client = useQueryClient();
    const navigate = useNavigate();

    const { mutate, isLoading, error, reset } = useMutation(['admin-login'], (data: { login: string, password: string }) => {
        return AdminNetwork.login(data);
    }, {
        async onSuccess() {
            await client.invalidateQueries(['admin']);
            navigate(getHomeRoute());
        }
    });

    return {
        login: mutate,
        isLoading,
        error: useMemo(() => {
            if (!error) return null

            //@ts-ignore
            const errorResponse: any = error.response;

            switch (errorResponse.status) {
                default:
                    return 'Błąd logowania'
            }
        }, [error]),
        reset
    };
}
