import { useForm } from "react-hook-form";
import styled from "styled-components";
import { GameStats, ChapterStats } from "../../modules/game/use-game-stats.types";
import CheckPointScore from "./checkpoints";

/**
 * Komponent prezentujący statystyki gry dla całej klasy
 */

type GameStatsProps = {
    stats: GameStats
}

const StatsContainer = styled.div`
    .controlls {
        display: flex;

        fieldset {
            flex: 1 1 50%;
            border-color: var(--color3);
        }
    }
`;

export default function GameStatsWidget({ stats }: GameStatsProps) {

    const { register, watch } = useForm<Pick<ChapterStatsProps, 'gameSet' | 'mathFunc' | 'endingType'>>({
        defaultValues: {
            mathFunc: 'avg',
            gameSet: 'all',
            endingType: 'success'
        }
    });

    const { mathFunc, gameSet, endingType } = watch();

    return <StatsContainer>
        <form>
            <div className="controlls">
                <fieldset>
                    <legend>Do wyliczeń używaj:</legend>
                    <label><input type="radio" value="all" {...register('gameSet')} /> Wszystkich gier</label><br />
                    <label><input type="radio" value="first" {...register('gameSet')} /> Pierwszej gry każdego gracza</label><br />
                    <label><input type="radio" value="last" {...register('gameSet')} /> Ostatniej gry każdego gracza</label>
                </fieldset>
                <fieldset>
                    <legend>Wyliczaj:</legend>
                    <label><input type="radio" value="avg" {...register('mathFunc')} /> Średnią</label><br />
                    <label><input type="radio" value="median" {...register('mathFunc')} /> Medianę</label>
                </fieldset>
                <fieldset>
                    <legend>Rodzaje zakończeń</legend>
                    <label><input type="radio" value="all" {...register('endingType')} /> Wszystkie</label><br />
                    <label><input type="radio" value="success" {...register('endingType')} /> Tylko pozytywne</label><br />
                    <label><input type="radio" value="early" {...register('endingType')} /> Tylko negatywne</label>
                </fieldset>
            </div>
        </form>
        <div className="chapters">
            <ChapterStatsWidget label="Rozdział 1" stats={stats.chapter1} gameSet={gameSet} mathFunc={mathFunc} endingType={endingType} />
            <ChapterStatsWidget label="Rozdział 2" stats={stats.chapter2} gameSet={gameSet} mathFunc={mathFunc} endingType={endingType} />
            <ChapterStatsWidget label="Rozdział 3" stats={stats.chapter3} gameSet={gameSet} mathFunc={mathFunc} endingType={endingType} />
            <ChapterStatsWidget label="Rozdział 4" stats={stats.chapter4} gameSet={gameSet} mathFunc={mathFunc} endingType={endingType} />
            <ChapterStatsWidget label="Rozdział 5" stats={stats.chapter5} gameSet={gameSet} mathFunc={mathFunc} endingType={endingType} />
        </div>
    </StatsContainer>
}

type ChapterStatsProps = {
    label: string,
    stats: ChapterStats,
    gameSet: 'all' | 'first' | 'last',
    mathFunc: 'avg' | 'median',
    endingType: 'all' | 'success' | 'early'
}

const ChapterStatsContainer = styled.div`
    display: grid;
    grid-template-columns: auto 2fr;
    grid-template-rows: 1fr 1fr;

    margin: .5em 0;
    padding: .5em;
    background: var(--color3);

    .label {
        font-size: 1.5em;

        display: flex;
        align-items: center;

        grid-column: 1;
        grid-row: 1;
    }

    .summary {
        font-size: .8em;

        grid-column: 1;
        grid-row: 2;

        .earlyEnd {
            font-size: .8em;
            strong {
                color: var(--colorError1);
            }
        }
    }

    .score-container {
        grid-column: 2;
        grid-row: 1/3;

        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
`;

function ChapterStatsWidget({ label, stats, gameSet, mathFunc, endingType }: ChapterStatsProps) {
    return <ChapterStatsContainer>
        <div className="label">
            {label}
        </div>
        <div className="summary">
            <p>Gracze, którzy przeszli ten rozdział przynajmniej jeden raz: <strong>{stats.players}</strong></p>
            <p>Łączna liczba rozegrań wszystkich graczy: <strong>{stats.plays}</strong></p>
            <p className="earlyEnd">W tym zakończonych przedwcześnie: <strong>{stats.earlyEndCount}</strong></p>
        </div>
        <div className="score-container">
            <CheckPointScore {...stats[endingType][gameSet][mathFunc]} />
        </div>

    </ChapterStatsContainer>
}
