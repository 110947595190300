import styled from "styled-components"
import pattern2Src from '../../assets/patterns/pattern-2.png';
import pattern3Src from '../../assets/patterns/pattern-3.png';
import pattern4Src from '../../assets/patterns/pattern-4.png';

type Checkpoint = {
    globals: {
        [key: string]: any
    }
}

const CheckPointScoreContainer = styled.div`
    display: flex;

    .score {

        width: 4em;
        height: 4em;

        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        .label {
            font-size: .75em;
        }

        &.eco {
            background: url(${pattern3Src});
            background-size: 20%;
            background-attachment: fixed;
        }
        &.edu {
            background: url(${pattern2Src});
            background-size: 20%;
            background-attachment: fixed;
        }
        &.gra {
            background: url(${pattern4Src});
            background-size: 20%;
            background-attachment: fixed;
        }
    }
`;

export default function CheckPointScore({ eco, edu, gra }: { eco: number | null, edu: number | null, gra: number | null }) {
    return <CheckPointScoreContainer>
        <div className="score eco">
            <div className="label">Eko</div>
            <div className="value">{eco === null ? '-' : eco.toFixed(1)}</div>
        </div>
        <div className="score edu">
            <div className="label">Edu</div>
            <div className="value">{edu === null ? '-' : edu.toFixed(1)}</div>
        </div>
        <div className="score gra">
            <div className="label">Gra</div>
            <div className="value">{gra === null ? '-' : gra.toFixed(1)}</div>
        </div>
    </CheckPointScoreContainer>
}
