import styled from "styled-components";
import { OpenGameStatsData } from "../../modules/game/use-open-game-stats"

type Props = {
    stats: OpenGameStatsData
}

const Container = styled.div`

    h3 {
        margin: 0 0 .5em 0;
    }

    .chapter {
        border: 1px solid grey;
        padding: .5em;
        margin: .5em 0;
    }

    h4 {
        margin: 1em 0 .2em;
    }

    h5 {
        margin: 0 0 .5em;
    }

    .col3 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        padding: .5em;
        background: #d9d9d9;
    }
`;

export default function OpenGameRaport({ stats }: Props) {
    console.log(stats);

    return <Container>
        <div>
            Ilość graczy: {stats.playersCount}
        </div>
        <div className="chapter">
            <h3>Rodział 1</h3>
            <div>
                Liczba gier: {stats.c1_plays}<br />
                Liczba negatywnych zakończeń: {stats.c1_early}
            </div>
            <div className="games">
                <h4>Wszystkie zakończenia</h4>
                <div className="col3">
                    <div>
                        <h5>Wszystkie gry</h5>
                        Eko: {stats.c1_all_all_avg_eco}<br />
                        Edu: {stats.c1_all_all_avg_edu}<br />
                        Gra: {stats.c1_all_all_avg_gra}<br />
                    </div>
                    <div>
                        <h5>Pierwsze gry</h5>
                        Eko: {stats.c1_all_first_avg_eco}<br />
                        Edu: {stats.c1_all_first_avg_edu}<br />
                        Gra: {stats.c1_all_first_avg_gra}<br />
                    </div>
                    <div>
                        <h5>Ostatnie gry</h5>
                        Eko: {stats.c1_all_last_avg_eco}<br />
                        Edu: {stats.c1_all_last_avg_edu}<br />
                        Gra: {stats.c1_all_last_avg_gra}<br />
                    </div>
                </div>
            </div>
            <div className="games">
                <h4>Pozytywne zakończenia</h4>
                <div className="col3">
                    <div>
                        <h5>Wszystkie gry</h5>
                        Eko: {stats.c1_success_all_avg_eco}<br />
                        Edu: {stats.c1_success_all_avg_edu}<br />
                        Gra: {stats.c1_success_all_avg_gra}<br />
                    </div>
                    <div>
                        <h5>Pierwsze gry</h5>
                        Eko: {stats.c1_success_first_avg_eco}<br />
                        Edu: {stats.c1_success_first_avg_edu}<br />
                        Gra: {stats.c1_success_first_avg_gra}<br />
                    </div>
                    <div>
                        <h5>Ostatnie gry</h5>
                        Eko: {stats.c1_success_last_avg_eco}<br />
                        Edu: {stats.c1_success_last_avg_edu}<br />
                        Gra: {stats.c1_success_last_avg_gra}<br />
                    </div>
                </div>
            </div>
            <div className="games">
                <h4>Negatywne zakończenia</h4>
                <div className="col3">
                    <div>
                        <h5>Wszystkie gry</h5>
                        Eko: {stats.c1_early_all_avg_eco}<br />
                        Edu: {stats.c1_early_all_avg_edu}<br />
                        Gra: {stats.c1_early_all_avg_gra}<br />
                    </div>
                    <div>
                        <h5>Pierwsze gry</h5>
                        Eko: {stats.c1_early_first_avg_eco}<br />
                        Edu: {stats.c1_early_first_avg_edu}<br />
                        Gra: {stats.c1_early_first_avg_gra}<br />
                    </div>
                    <div>
                        <h5>Ostatnie gry</h5>
                        Eko: {stats.c1_early_last_avg_eco}<br />
                        Edu: {stats.c1_early_last_avg_edu}<br />
                        Gra: {stats.c1_early_last_avg_gra}<br />
                    </div>
                </div>
            </div>
        </div><div className="chapter">
            <h3>Rodział 2</h3>
            <div>
                Liczba gier: {stats.c2_plays}<br />
                Liczba negatywnych zakończeń: {stats.c2_early}
            </div>
            <div className="games">
                <h4>Wszystkie zakończenia</h4>
                <div className="col3">
                    <div>
                        <h5>Wszystkie gry</h5>
                        Eko: {stats.c2_all_all_avg_eco}<br />
                        Edu: {stats.c2_all_all_avg_edu}<br />
                        Gra: {stats.c2_all_all_avg_gra}<br />
                    </div>
                    <div>
                        <h5>Pierwsze gry</h5>
                        Eko: {stats.c2_all_first_avg_eco}<br />
                        Edu: {stats.c2_all_first_avg_edu}<br />
                        Gra: {stats.c2_all_first_avg_gra}<br />
                    </div>
                    <div>
                        <h5>Ostatnie gry</h5>
                        Eko: {stats.c2_all_last_avg_eco}<br />
                        Edu: {stats.c2_all_last_avg_edu}<br />
                        Gra: {stats.c2_all_last_avg_gra}<br />
                    </div>
                </div>
            </div>
            <div className="games">
                <h4>Pozytywne zakończenia</h4>
                <div className="col3">
                    <div>
                        <h5>Wszystkie gry</h5>
                        Eko: {stats.c2_success_all_avg_eco}<br />
                        Edu: {stats.c2_success_all_avg_edu}<br />
                        Gra: {stats.c2_success_all_avg_gra}<br />
                    </div>
                    <div>
                        <h5>Pierwsze gry</h5>
                        Eko: {stats.c2_success_first_avg_eco}<br />
                        Edu: {stats.c2_success_first_avg_edu}<br />
                        Gra: {stats.c2_success_first_avg_gra}<br />
                    </div>
                    <div>
                        <h5>Ostatnie gry</h5>
                        Eko: {stats.c2_success_last_avg_eco}<br />
                        Edu: {stats.c2_success_last_avg_edu}<br />
                        Gra: {stats.c2_success_last_avg_gra}<br />
                    </div>
                </div>
            </div>
            <div className="games">
                <h4>Negatywne zakończenia</h4>
                <div className="col3">
                    <div>
                        <h5>Wszystkie gry</h5>
                        Eko: {stats.c2_early_all_avg_eco}<br />
                        Edu: {stats.c2_early_all_avg_edu}<br />
                        Gra: {stats.c2_early_all_avg_gra}<br />
                    </div>
                    <div>
                        <h5>Pierwsze gry</h5>
                        Eko: {stats.c2_early_first_avg_eco}<br />
                        Edu: {stats.c2_early_first_avg_edu}<br />
                        Gra: {stats.c2_early_first_avg_gra}<br />
                    </div>
                    <div>
                        <h5>Ostatnie gry</h5>
                        Eko: {stats.c2_early_last_avg_eco}<br />
                        Edu: {stats.c2_early_last_avg_edu}<br />
                        Gra: {stats.c2_early_last_avg_gra}<br />
                    </div>
                </div>
            </div>
        </div><div className="chapter">
            <h3>Rodział 3</h3>
            <div>
                Liczba gier: {stats.c3_plays}<br />
                Liczba negatywnych zakończeń: {stats.c3_early}
            </div>
            <div className="games">
                <h4>Wszystkie zakończenia</h4>
                <div className="col3">
                    <div>
                        <h5>Wszystkie gry</h5>
                        Eko: {stats.c3_all_all_avg_eco}<br />
                        Edu: {stats.c3_all_all_avg_edu}<br />
                        Gra: {stats.c3_all_all_avg_gra}<br />
                    </div>
                    <div>
                        <h5>Pierwsze gry</h5>
                        Eko: {stats.c3_all_first_avg_eco}<br />
                        Edu: {stats.c3_all_first_avg_edu}<br />
                        Gra: {stats.c3_all_first_avg_gra}<br />
                    </div>
                    <div>
                        <h5>Ostatnie gry</h5>
                        Eko: {stats.c3_all_last_avg_eco}<br />
                        Edu: {stats.c3_all_last_avg_edu}<br />
                        Gra: {stats.c3_all_last_avg_gra}<br />
                    </div>
                </div>
            </div>
        </div>
        <div className="chapter">
            <h3>Rodział 4</h3>
            <div>
                Liczba gier: {stats.c4_plays}<br />
                Liczba negatywnych zakończeń: {stats.c4_early}
            </div>
            <div className="games">
                <h4>Wszystkie zakończenia</h4>
                <div className="col3">
                    <div>
                        <h5>Wszystkie gry</h5>
                        Eko: {stats.c4_all_all_avg_eco}<br />
                        Edu: {stats.c4_all_all_avg_edu}<br />
                        Gra: {stats.c4_all_all_avg_gra}<br />
                    </div>
                    <div>
                        <h5>Pierwsze gry</h5>
                        Eko: {stats.c4_all_first_avg_eco}<br />
                        Edu: {stats.c4_all_first_avg_edu}<br />
                        Gra: {stats.c4_all_first_avg_gra}<br />
                    </div>
                    <div>
                        <h5>Ostatnie gry</h5>
                        Eko: {stats.c4_all_last_avg_eco}<br />
                        Edu: {stats.c4_all_last_avg_edu}<br />
                        Gra: {stats.c4_all_last_avg_gra}<br />
                    </div>
                </div>
            </div>
        </div>
        <div className="chapter">
            <h3>Rodział 5</h3>
            <div>
                Liczba gier: {stats.c5_plays}<br />
                Liczba negatywnych zakończeń: {stats.c5_early}
            </div>
            <div className="games">
                <h4>Wszystkie zakończenia</h4>
                <div className="col3">
                    <div>
                        <h5>Wszystkie gry</h5>
                        Eko: {stats.c5_all_all_avg_eco}<br />
                        Edu: {stats.c5_all_all_avg_edu}<br />
                        Gra: {stats.c5_all_all_avg_gra}<br />
                    </div>
                    <div>
                        <h5>Pierwsze gry</h5>
                        Eko: {stats.c5_all_first_avg_eco}<br />
                        Edu: {stats.c5_all_first_avg_edu}<br />
                        Gra: {stats.c5_all_first_avg_gra}<br />
                    </div>
                    <div>
                        <h5>Ostatnie gry</h5>
                        Eko: {stats.c5_all_last_avg_eco}<br />
                        Edu: {stats.c5_all_last_avg_edu}<br />
                        Gra: {stats.c5_all_last_avg_gra}<br />
                    </div>
                </div>
            </div>
        </div>
    </Container>
}
