import { useMemo } from "react";
import styled from "styled-components";

type Props = {
    gameMasters: any
}

const Container = styled.div``;

export default function SummaryRaport({gameMasters}: Props) {
    const {gameMasterCount, playersCreated, playersLogged, gamesCreated} = useSummaryData(gameMasters);

    return <Container>
        <h2>Podsumowanie</h2>
        <table>
            <tbody>
                <tr>
                    <td>Ilość zarejestrowanych nauczycieli:</td>
                    <td>{gameMasterCount}</td>
                </tr>
                <tr>
                    <td>Ilość założonych gier:</td>
                    <td>{gamesCreated}</td>
                </tr>
                <tr>
                    <td>Ilość wygenerowanych kodów gry:</td>
                    <td>{playersCreated}</td>
                </tr>
                <tr>
                    <td>Ilość graczy którzy zalogowali się do gry:</td>
                    <td>{playersLogged}</td>
                </tr>
            </tbody>
        </table>
    </Container>
}

function useSummaryData(gameMasters: any) {

    return useMemo(() => {
        return gameMasters.reduce((result: any, next: any) => {
            result.gameMasterCount += 1;
            result.gamesCreated += next.games.length;

            next.games.forEach((game: any) => {
                result.playersCreated += game.playerCount;
                result.playersLogged += game.playersLogged;
            });

            return result;
        }, {
            gameMasterCount: 0,
            playersCreated: 0,
            playersLogged: 0,
            gamesCreated: 0,
        })
    }, [gameMasters]);
}
