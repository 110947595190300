import { useMemo } from "react";
import { GameInfoData, PlayerGameResult, PlayerData } from "../network/types";
import { GameStats, ScoreStats, ChapterStats } from "./use-game-stats.types";

export interface GameInfo {
    code: string,
    createdAt: string,
    scenarioName: string,
    description: string,

    playerTokens: string[],
    unusedPlayerTokens: string[]

    stats: GameStats,
    players: PlayerInfo[]
}

export interface PlayerChapterInfo {
    count: number,
    earlyEndingCount: number,

    all: {
        first: ScoreStats & { earlyEnding: boolean },
        last: ScoreStats & { earlyEnding: boolean }
    },
    success: {
        first: ScoreStats & { earlyEnding: boolean },
        last: ScoreStats & { earlyEnding: boolean }
    },
    early: {
        first: ScoreStats & { earlyEnding: boolean },
        last: ScoreStats & { earlyEnding: boolean }
    }
}

export interface PlayerInfo {
    token: string,
    used: boolean,

    chapter1: PlayerChapterInfo
    chapter2: PlayerChapterInfo
    chapter3: PlayerChapterInfo
    chapter4: PlayerChapterInfo
    chapter5: PlayerChapterInfo
}

export default function useGameStats(game?: GameInfoData): GameInfo | undefined {
    return useMemo(() => {
        if (!game) return game;

        return {
            code: game.code,
            createdAt: game.createdAt,
            scenarioName: game.scenario.name,
            description: game.description,

            playerTokens: game.players.map((p: any) => p.token),
            unusedPlayerTokens: game.players.filter((p: any) => !p.used).map((p: any) => p.token),

            stats: calculateStats(game),

            players: game.players.map(p => parsePlayer(p))
        }
    }, [game]);
}


function calculateStats(data: GameInfoData): GameStats {

    return {
        chapter1: calculateScores(data, 1),
        chapter2: calculateScores(data, 2),
        chapter3: calculateScores(data, 3),
        chapter4: calculateScores(data, 4),
        chapter5: calculateScores(data, 5),
    }
}

function avg(values: number[]) {
    if (!values.length) return null;
    const sum = values.reduce((prev, next) => prev + next, 0);

    return sum / values.length;
}

function median(numbers: number[]) {
    if (!numbers.length) return null;
    const sorted = Array.from(numbers).sort((a, b) => a - b);
    const middle = Math.floor(sorted.length / 2);

    if (sorted.length % 2 === 0) {
        return (sorted[middle - 1] + sorted[middle]) / 2;
    }

    return sorted[middle];
}

function getChapterResults(data: GameInfoData, chapterIndex: number) {
    return data.players.reduce(
        (prev: PlayerGameResult[], next: PlayerData) => {
            return [
                ...prev,
                ...next.results
                    .filter(r => r.chapter === chapterIndex)
            ];
        }, []);
}

function getFirstChapterResults(data: GameInfoData, chapterIndex: number) {
    return data.players.reduce(
        (prev: PlayerGameResult[], next: PlayerData) => {
            const results = next.results.filter(r => r.chapter === chapterIndex)

            if (!results.length) return prev;

            return [...prev, results[0]];
        }, []);
}

function getLastChapterResults(data: GameInfoData, chapterIndex: number) {
    return data.players.reduce(
        (prev: PlayerGameResult[], next: PlayerData) => {
            const results = next.results.filter(r => r.chapter === chapterIndex)

            if (!results.length) return prev;

            return [...prev, results[results.length - 1]];
        }, []);
}

function calculateScores(gameData: GameInfoData, chapter: number): ChapterStats {

    const results = getChapterResults(gameData, chapter);
    const firstResult = getFirstChapterResults(gameData, chapter);
    const lastResult = getLastChapterResults(gameData, chapter);

    const successResults = results.filter(r => !r.ending || r.ending === 'success');
    const successFirstResult = firstResult.filter(r => !r.ending || r.ending === 'success');
    const successLastResult = lastResult.filter(r => !r.ending || r.ending === 'success');

    const earlyResults = results.filter(r => r.ending && r.ending !== 'success');
    const earlyFirstResult = firstResult.filter(r => r.ending && r.ending !== 'success');
    const earlyLastResult = lastResult.filter(r => r.ending && r.ending !== 'success');

    return {
        plays: results.length,
        players: gameData.players.filter(p => p.results.some(r => r.chapter === chapter)).length,
        earlyEndCount: results.filter(r => r.ending && r.ending !== 'success').length,
        all: {
            all: {
                avg: {
                    eco: avg(results.map(r => r.eco)),
                    edu: avg(results.map(r => r.edu)),
                    gra: avg(results.map(r => r.gra)),
                },
                median: {
                    eco: median(results.map(r => r.eco)),
                    edu: median(results.map(r => r.edu)),
                    gra: median(results.map(r => r.gra)),
                }
            },
            first: {
                avg: {
                    eco: avg(firstResult.map(r => r.eco)),
                    edu: avg(firstResult.map(r => r.edu)),
                    gra: avg(firstResult.map(r => r.gra)),
                },
                median: {
                    eco: median(firstResult.map(r => r.eco)),
                    edu: median(firstResult.map(r => r.edu)),
                    gra: median(firstResult.map(r => r.gra)),
                }
            },
            last: {
                avg: {
                    eco: avg(lastResult.map(r => r.eco)),
                    edu: avg(lastResult.map(r => r.edu)),
                    gra: avg(lastResult.map(r => r.gra)),
                },
                median: {
                    eco: median(lastResult.map(r => r.eco)),
                    edu: median(lastResult.map(r => r.edu)),
                    gra: median(lastResult.map(r => r.gra)),
                }
            }
        },
        success: {
            all: {
                avg: {
                    eco: avg(successResults.map(r => r.eco)),
                    edu: avg(successResults.map(r => r.edu)),
                    gra: avg(successResults.map(r => r.gra)),
                },
                median: {
                    eco: median(successResults.map(r => r.eco)),
                    edu: median(successResults.map(r => r.edu)),
                    gra: median(successResults.map(r => r.gra)),
                }
            },
            first: {
                avg: {
                    eco: avg(successFirstResult.map(r => r.eco)),
                    edu: avg(successFirstResult.map(r => r.edu)),
                    gra: avg(successFirstResult.map(r => r.gra)),
                },
                median: {
                    eco: median(successFirstResult.map(r => r.eco)),
                    edu: median(successFirstResult.map(r => r.edu)),
                    gra: median(successFirstResult.map(r => r.gra)),
                }
            },
            last: {
                avg: {
                    eco: avg(successLastResult.map(r => r.eco)),
                    edu: avg(successLastResult.map(r => r.edu)),
                    gra: avg(successLastResult.map(r => r.gra)),
                },
                median: {
                    eco: median(successLastResult.map(r => r.eco)),
                    edu: median(successLastResult.map(r => r.edu)),
                    gra: median(successLastResult.map(r => r.gra)),
                }
            }
        },
        early: {
            all: {
                avg: {
                    eco: avg(earlyResults.map(r => r.eco)),
                    edu: avg(earlyResults.map(r => r.edu)),
                    gra: avg(earlyResults.map(r => r.gra)),
                },
                median: {
                    eco: median(earlyResults.map(r => r.eco)),
                    edu: median(earlyResults.map(r => r.edu)),
                    gra: median(earlyResults.map(r => r.gra)),
                }
            },
            first: {
                avg: {
                    eco: avg(earlyFirstResult.map(r => r.eco)),
                    edu: avg(earlyFirstResult.map(r => r.edu)),
                    gra: avg(earlyFirstResult.map(r => r.gra)),
                },
                median: {
                    eco: median(earlyFirstResult.map(r => r.eco)),
                    edu: median(earlyFirstResult.map(r => r.edu)),
                    gra: median(earlyFirstResult.map(r => r.gra)),
                }
            },
            last: {
                avg: {
                    eco: avg(earlyLastResult.map(r => r.eco)),
                    edu: avg(earlyLastResult.map(r => r.edu)),
                    gra: avg(earlyLastResult.map(r => r.gra)),
                },
                median: {
                    eco: median(earlyLastResult.map(r => r.eco)),
                    edu: median(earlyLastResult.map(r => r.edu)),
                    gra: median(earlyLastResult.map(r => r.gra)),
                }
            }
        }
    }
}

function parsePlayer(player: PlayerData): PlayerInfo {

    const c1Results = player.results.filter(r => r.chapter === 1);
    const c2Results = player.results.filter(r => r.chapter === 2);
    const c3Results = player.results.filter(r => r.chapter === 3);
    const c4Results = player.results.filter(r => r.chapter === 4);
    const c5Results = player.results.filter(r => r.chapter === 5);

    const result: PlayerInfo = {
        token: player.token,
        used: player.used,
        chapter1: parsePlayerChapter(c1Results),
        chapter2: parsePlayerChapter(c2Results),
        chapter3: parsePlayerChapter(c3Results),
        chapter4: parsePlayerChapter(c4Results),
        chapter5: parsePlayerChapter(c5Results),
    }

    return result;
}

function parsePlayerChapter(results: PlayerGameResult[]): PlayerChapterInfo {

    const size = results.length;

    const first = results[0];
    const last = results[size - 1];

    const firstSuccess = results.find(r => !r.ending || r.ending === 'success');
    const firstEarly = results.find(r => r.ending && r.ending === 'success');

    const lastSuccess = findLast(results, r => !r.ending || r.ending === 'success');
    const lastEarly = findLast(results, r => r.ending && r.ending === 'success');


    return {
        count: size,
        earlyEndingCount: results.filter(r => r.ending && r.ending !== 'success').length,
        all: {
            first: {
                earlyEnding: first ? Boolean(first.ending && first.ending !== 'success') : false,
                eco: first ? first.eco : null,
                edu: first ? first.edu : null,
                gra: first ? first.gra : null,
            },
            last: {
                earlyEnding: last ? Boolean(last.ending && last.ending !== 'success') : false,
                eco: last ? last.eco : null,
                edu: last ? last.edu : null,
                gra: last ? last.gra : null,
            }
        },
        success: {
            first: {
                earlyEnding: false,
                eco: firstSuccess ? firstSuccess.eco : null,
                edu: firstSuccess ? firstSuccess.edu : null,
                gra: firstSuccess ? firstSuccess.gra : null,
            },
            last: {
                earlyEnding: false,
                eco: lastSuccess ? lastSuccess.eco : null,
                edu: lastSuccess ? lastSuccess.edu : null,
                gra: lastSuccess ? lastSuccess.gra : null,
            }
        },
        early: {
            first: {
                earlyEnding: true,
                eco: firstEarly ? firstEarly.eco : null,
                edu: firstEarly ? firstEarly.edu : null,
                gra: firstEarly ? firstEarly.gra : null,
            },
            last: {
                earlyEnding: true,
                eco: lastEarly ? lastEarly.eco : null,
                edu: lastEarly ? lastEarly.edu : null,
                gra: lastEarly ? lastEarly.gra : null,
            }
        }
    }
}

function findLast<T>(array: T[], callbackFn: (item: T) => any): T | null {

    for (let i = array.length - 1; i >= 0; i--) {
        if (Boolean(callbackFn(array[i]))) {
            return array[i];
        }
    }

    return null;
}
