import styled from "styled-components"
import useOpenGameStats from "../../modules/game/use-open-game-stats";
import OpenGameRaport from "./raport";

const Container = styled.div`
    margin: 1em;
`;

export default function OpenGameStats() {

    const {data, isLoading} = useOpenGameStats();

    return <Container>
        {isLoading && <p>Ładujemy...</p>}
        {!isLoading && <OpenGameRaport stats={data} />}
    </Container>
}
