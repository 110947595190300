import { useState } from "react";
import styled from "styled-components";
import { GameInfo } from "../../modules/game/use-game-stats";
import GameStatsWidget from "./game-stats";
import PlayersStats from "./players-stats";

const Container = styled.div`
    margin: 2em auto;
    max-width: 1200px;
    width: 90%;
    padding: 0 1.5em 1.5em;

    border: 1px solid black;
    border-radius: 1em;

    background: var(--color1);

    h2 {
        border-bottom: solid 1px black;
        text-align: center;
    }

    .players-count {
        margin: .5em 0;
    }

    .player {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 1em;

        background: #d9d9d9;
        margin-bottom: 2px;

        &:nth-child(2n) {
            background: #9d9d9d;
        }
    }

    .tools {
        background: var(--color3);
        padding: 1em;
        border-radius: 1em;


        button {
            display: inline-block;
            margin: 0 .5em 0 0;
        }

        textarea {
            width: 100%;
            height: 10em;
        }
    }
`;

export default function GameMasterGameInfo({ game }: { game: GameInfo }) {

    const [toolsPanel, setToolsPanel] = useState<null | 'all-tokens' | 'available-tokens' | 'new-tokens'>(null);

    return <Container>
        <h2>Gra: {game.code}</h2>
        <p>Data utworzenia: <strong>{game.createdAt}</strong></p>
        <p>Scenariusz: <strong>{game.scenarioName}</strong></p>
        <p>Opis:<br /> <strong>{game.description}</strong></p>
        <div>
            <h3>Wyniki grupy</h3>
            <div className="players-count">
                <p>Liczba wszystkich graczy: <strong>{game.playerTokens.length}</strong></p>
                <p>Gracze, którzy się zalogowali: <strong>{game.playerTokens.length - game.unusedPlayerTokens.length}</strong></p>
            </div>
            <GameStatsWidget stats={game.stats} />
        </div>
        <div>
            <h4>Narzędzia</h4>
            <div className="tools">
                <div className="tools-menu">
                    <button onClick={() => setToolsPanel('all-tokens')}>Pokaż wszystkie tokeny</button>
                    <button onClick={() => setToolsPanel('available-tokens')}>Pokaż nieużyte tokeny</button>
                </div>
                {toolsPanel === 'all-tokens' && <div>
                    <h5>Wszystkie tokeny graczy</h5>
                    <textarea readOnly defaultValue={game.playerTokens.join('\n')} />
                    <button onClick={() => setToolsPanel(null)}>Zamknij</button>
                </div>}
                {toolsPanel === 'available-tokens' && <div>
                    <h5>Nieużyte tokeny graczy</h5>
                    <textarea readOnly defaultValue={game.unusedPlayerTokens.join('\n')} />
                    <button onClick={() => setToolsPanel(null)}>Zamknij</button>
                </div>}
            </div>
            <PlayersStats game={game} />
        </div>
    </Container>
}


