import { useMutation, useQueryClient } from "@tanstack/react-query";
import AdminNetwork from "../../network/admin";

export default function useAdminLogout() {
    const client = useQueryClient();

    const {mutate} = useMutation(['admin-logout'], AdminNetwork.logout, {
        onSuccess() {
            client.invalidateQueries(['admin'])
        }
    });

    return mutate;
}
