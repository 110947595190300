import { useMutation, useQuery } from "@tanstack/react-query";
import styled from "styled-components"
import AdminNetwork from "../modules/network/admin";

const Container = styled.div`
    font-size: 16px;

    .error {
        color: var(--colorError1);
    }

    table {

        td {
            padding: .5em;
        }

        tbody {

            tr:nth-child(2n) {
                background: #9d9d9d;
            }

            tr:nth-child(2n + 1) {
                background: #d9d9d9;
            }
        }
    }
`;

export default function ScenariosConvertionPanel() {

    const { isLoading, data: projectList, isError: projectsError } = useProjectsList()

    const { mutate, isLoading: isConvering, isError } = useMutation({
        mutationFn: async (dir: string) => {
            await AdminNetwork.convertArticyProject(dir);
        }
    });


    return <Container>
        <h2>Konwersja scenariuszy</h2>

        {projectsError && <p className="error">Wystąpił błąd po stronie serwera, sprawdż serwer i spróbuj ponownie</p>}
        {isLoading && <p>Ładuję...</p>}
        {projectList && <table>
            <thead>
                <tr>
                    <td>Katalog ze scenariuszem</td>
                    <td></td>
                </tr>
            </thead>
            <tbody>
                {
                    projectList.map((p: string) => {
                        return <tr>
                            <td>
                                {p}
                            </td>
                            <td>
                                {!isConvering && <button onClick={() => mutate(p)}>Zrób konwersje</button>}
                                {isConvering && <p>Trwa konwersja</p>}
                                {isError && <span className="error"> Konwersja się nie udała. Sprawdź logi serwera</span>}
                            </td>
                        </tr>
                    })
                }
            </tbody>
        </table>
        }
    </Container>
}


function useProjectsList() {
    const { isLoading, data, isError } = useQuery({
        queryKey: ['projects'],
        queryFn: AdminNetwork.getProjectsLists
    });

    return { isLoading, data, isError }
}
