import { useMemo } from "react";
import styled from "styled-components";
import useGMRaport from "../modules/admin/hooks/use-gm-raport";
import GameMasterRaport from "./game-master-raport";
import SummaryRaport from "./summary-raport";

const Container = styled.div`
    .download-pdf {
        padding: .4em;
        background: #eee;
        text-align: right;
    }
`;

export default function GMRaport() {

    const { gameMasters, isLoading } = useGMRaport();

    const validGMs = useMemo(() => {
        return (gameMasters || []).filter((gm: any) => !gm.hidden)
    }, [gameMasters]);

    const raportUrl = process.env.REACT_APP_SERVER_URL + '/admin/gm-raport-pdf';

    return <Container>
        {isLoading && <p>Ładuję...</p>}
        {!isLoading && <>
            <div className="download-pdf">
                <a href={raportUrl} download>Pobierz raport PDF</a>
            </div>
            <SummaryRaport gameMasters={validGMs} />
            <h2>Nauczyciele</h2>
            {validGMs.map((gm: any) => {
                return <GameMasterRaport gm={gm} key={gm._id} />
            })}
        </>}
    </Container>
}
