import { useQuery } from "@tanstack/react-query"
import AdminNetwork from "../../network/admin"

export default function useAdmin() {
    const { data, isLoading, isError, isSuccess } = useQuery(['admin'], AdminNetwork.getAdmin, {
        staleTime: Infinity,
        retry(failureCount, error: any) {
            const status = error.response.status;
            if (status >= 400 && status < 500) return false;
            return failureCount < 3;
        }
    })

    return {
        isLoading,
        isError,
        isSuccess
    }
}
