import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import AdminNetwork from "../../modules/network/admin";
import { useCallback } from "react";

type Props = {
    defaultGameScenario?: string
}

export default function OpenGameForm({defaultGameScenario}: Props) {
    const {register, handleSubmit} = useForm({
        defaultValues: {
            scenarioId: defaultGameScenario
        }
    });

    const { isLoading, data: scenarios } = useQuery({
        queryKey: ['scenarios-all'],
        queryFn: AdminNetwork.getFullScenarioList
    });


    const client = useQueryClient();

    const { mutate } = useMutation({ mutationFn: AdminNetwork.updateOpenGame, onSuccess() {
        client.invalidateQueries({queryKey: ['game', 'default']});
    } });

    const onSubmit = useCallback((data) => {
        mutate({
            scenarioId: data.scenarioId
        });
    }, [mutate]);

    return <>
        {!isLoading && <form onSubmit={handleSubmit(onSubmit)}>
            <div>
                <label>
                    Scenariusz dla gry otwartej: <select {...register('scenarioId')}>
                        {
                            scenarios.map((s: any) => {
                                return <option key={s._id} value={s._id}>{s.name}</option>
                            })
                        }
                    </select>
                </label>
            </div>
            <button>Zapisz</button>
        </form>}
    </>
}
