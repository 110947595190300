import { Link } from "react-router-dom";
import styled from "styled-components";
import useAdminLogout from "../modules/admin/hooks/use-admin-logout";
import { getConvertRoute, getGameMastersRoute, getScenariosPanelRoute, getHiddenGMRoute, getOpenGameRoute } from "../routes/routes";

const Container = styled.div`
    nav {
        padding: .5em;
        display: flex;
        /* justify-content: space-between; */

        border-bottom: 1px solid black;

        a {
            padding: .5em;
            margin-left: .5em;
            background: #d9d9d9;
        }
    }
`;

export default function SiteMenu() {

    const logout = useAdminLogout();

    return <Container>
        <h1>Ekohakaton - panel admina <button onClick={() => logout()}>Wyloguj</button></h1>
        <nav>
            <Link to={getConvertRoute()}>Konwersja projektów</Link>
            <Link to={getScenariosPanelRoute()}>Scenariusze</Link>
            <Link to={getOpenGameRoute()}>Otwarta gra</Link>
            <Link to={getGameMastersRoute()}>Dane nauczycieli</Link>
            <Link to={getHiddenGMRoute()}>Ukryte konta</Link>
        </nav>
    </Container>
}
