import { useParams } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";
import GameMasterGameInfo from "../../components/game-info";
import useAdminGameInfo from "../../modules/admin/hooks/use-admin-game-info";
import { getGameMastersRoute } from "../routes";

const Container = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;

    font-size: 16pt;

    .loader {
        position: relative;

        margin: 2em;

        width: 100%;
        height: 50vh;
    }
`;

export default function AdminGameRaportPage() {
    const { gameCode } = useParams<{ gameCode: string }>();

    const { game, isLoading } = useAdminGameInfo(gameCode || '');

    const showLoader = isLoading;

    return <Container>
        <h1>Statystyki gry (panel admina)</h1>
        <nav>
            <Link to={getGameMastersRoute()}>Powrót</Link>
        </nav>
        {showLoader && <p>Ładuję...</p>}
        {game && <GameMasterGameInfo game={game} />}
    </Container>
}
