import { useQuery } from "@tanstack/react-query";
import useGameStats, { GameInfo } from "../../game/use-game-stats";
import AdminNetwork from "../../network/admin";

interface UseAdminGameInfoResult {
    isLoading: boolean,
    game: GameInfo | undefined
}

export default function useAdminGameInfo(code: string): UseAdminGameInfoResult {
    const { data, isLoading } = useQuery(['admin-game-info', code], () => AdminNetwork.getGameInfo(code));

    const game = useGameStats(data);

    return {
        game,
        isLoading
    }
}
