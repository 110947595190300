import { useQuery } from "@tanstack/react-query";
import AdminNetwork from "../network/admin";

export default function useGame(code: string) {
    const {data, isLoading, error} = useQuery(
        ['game', code],
        () => AdminNetwork.getGame(code),
        {
            retry: false
        }
    );

    return {
        game: data,
        isLoading,
        error
    }
}
