import { useQuery } from "@tanstack/react-query"
import AdminNetwork from "../network/admin"

export type OpenGameStatsData = any

export default function useOpenGameStats() : {isLoading: boolean, data: OpenGameStatsData} {

    const {data, isLoading} = useQuery(['open-game-stats'], AdminNetwork.getOpenGameStats);



    return {
        isLoading,
        data: data || {}
    }
}
