export function getHomeRoute() {
    return '/';
}

export function getLoginRoute() {
    return '/login';
}

export function getAdminPanelRoute() {
    return '/panel';
}

export function getGameRaportRoute(gameCode: string = ':gameCode') {
    return `/game/${gameCode}`;
}

export function getScenariosPanelRoute() {
    return '/scenarios';
}

export function getConvertRoute() {
    return '/convert';
}

export function getGameMastersRoute() {
    return '/game-masters';
}

export function getHiddenGMRoute() {
    return '/hidden-gm';
}

export function getOpenGameRoute() {
    return '/open-game';
}
