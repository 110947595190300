import styled from "styled-components";
import useGame from "../../modules/game/use-game";
import OpenGameForm from "./form";
import OpenGameStats from "./stats";

const Container = styled.div`
    .stats {
        margin: 1em;
    }

    .form {
        margin: 1em;
    }
`;

export default function OpenGame() {

    const {game, isLoading, error} = useGame('default');

    return <Container>
        <div className="stats">
            <h2>Statystyki</h2>
            <OpenGameStats />
        </div>
        <hr />
        <div className="form">
            <h2>Ustawienia</h2>
            {!isLoading && <OpenGameForm
                defaultGameScenario={error ? null : game.scenario}
            /> }
        </div>
    </Container>
}
